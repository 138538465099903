<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex lg12>
        <v-card>
          <hpo-filter
            :headers="headers"
            :viewName="viewName"
            v-on:applyFilter="applyFilter"
            v-on:quickFilter="quickFilter"
          ></hpo-filter>
          <v-data-table fixed-header height="750px"  
            :headers="headers"
            :items="items"
            :options.sync="pagination"
            :server-items-length="total_rows"
            :footer-props="items_per_page"
            :must-sort="true"
            :loading="loading"
            :mobile-breakpoint="100"
          >
            <template v-slot:item="{ item }">
              <tr :id="'r-' + item.ID">
                <td class="text-end">{{ item.VolumeMin | currency('', 2) }}</td>
                <td class="text-end">{{ item.VolumeMax | currency('', 2) }}</td>
                <td class="text-end">{{ item.Amount | currency('', 1) }}</td>
                <td>{{ (item.ModifiedDate || item.CreatedDate) | display_date }}</td>
                <td>{{ item.ModifiedBy }}</td>
                <td class="text-center">
                  <v-icon medium class="mr-2" @click="gotoDetail(item.ID)"
                    >fa-edit</v-icon
                  >
                  <v-icon medium @click="deleteItem(item.ID)"
                    >fa-trash-alt</v-icon
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import _ from "lodash";

export default {
  components: {
    "hpo-filter": Filtering,
  },
  data() {
    let dataType = configs.DATA_TYPE;
    return {
      headers: [
        {
          text: "Số khối (Min)",
          value: "VolumeMin",
          align: "end",
          filterable: true,
          dataType: dataType["Number"],
        },
        {
          text: "Số khối (Max)",
          value: "VolumeMax",
          align: "end",
          filterable: true,
          dataType: dataType["Number"],
        },
        {
          text: "Tiền phí (¥)",
          value: "Amount",
          align: "end",
          filterable: true,
          dataType: dataType["Number"],
        },
        {
          text: "Ngày sửa",
          value: "ModifiedDate",
          filterable: true,
          dataType: dataType["Date"],
        },
        {
          text: "Người sửa",
          value: "ModifiedBy",
          filterable: true,
          dataType: dataType["String"],
        },
        {
          text: "Thao tác",
          value: "",
          align: "center",
          sortable: false,
          filterable: false,
        },
      ],
      pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      viewName: "feeReinforce_list",
      filterConditions: [],
      quickFilterConditions: [],
      requireRules: [(v) => !!v || "Trường dữ liệu không được để trống."],
    };
  },
  watch: {
    pagination: {
      handler: function (val, oldVal) {
        let filterConditions = this.$store.state.commons.filterConditions;
        this.filterConditions = !_.isEmpty(filterConditions)
          ? filterConditions[this.viewName]
          : [];
        this.filter_data();
      },
      deep: true,
    }
  },
  computed: mapState({
    items: (state) => state.feeReinforce.all.data,
    total_rows: (state) => state.feeReinforce.all.total,
    loading: (state) => state.feeReinforce.loading
  }),
  methods: {
    filter_data: function () {
      var param = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions,
      };
      this.$store.dispatch("feeReinforce/getList", param);
    },
    gotoDetail: function (id) {
      this.$router.push({
        name: "FeeReinforceDetail",
        params: {
          Pid: id,
          title: `Chi tiết phí gia cố (#${id})`,
        },
      });
    },
    deleteItem: function (id) {
      window.getApp.$emit("REQUEST_DELETE_DATA", id);
    },
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    },
    createData(username = "") {
      this.$router.push({
        name: "FeeReinforceDetail",
        params: {
          Pid: "tao-moi",
          title: `Tạo mới phí gia cố`,
        },
      });
    }
  },
  mounted() {
    this.$root.$emit("REQUEST_ROOT_OPTION", {});
    this.$root.$on("REQUEST_CREATE_DATA", this.createData);
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$on("CONFIRMED_REMOVE_DATA", (id) => {
      this.$store.dispatch("feeReinforce/removeDetail", id);
      setTimeout(this.filter_data, 200);
    });
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_CREATE_DATA", this.createData);
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$off("CONFIRMED_REMOVE_DATA");
  },
};
</script>
